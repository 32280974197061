import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { writeGlobalState, readGlobalState } from '../../../hooks/StateHook';
import styles from './DocType.module.css';
import { useTranslation } from 'react-i18next';


export default function DocType({ values, title }) {
    const [type, setType] = useState('')
    const [setGlobalState] = writeGlobalState();
    const [allGlobals] = readGlobalState();
    const { t } = useTranslation();

    const onChangeHandler = (event) => {
        setType(event.target.value);
        setGlobalState({
            documentTypes: event.target.value,
            clearFilter: false
          })
    }

    useEffect(() => { 
        setType('')
      }, [values])
    
      useEffect(() => { 
        if (allGlobals.clearFilter) setType('')
      }, [allGlobals.clearFilter])

    return (
        <div className={styles.dropDownContainer}>
            <div className={styles.label}>{title}</div>
            <div>
                <select
                    value={type}
                    onChange={onChangeHandler}
                    className={styles.dropDown}
                >
                    <option disabled={true} value=""> {t('selectDocument')}</option>
                    {values.map((data) => <option key={data} value={data}>{t(data)}</option>)}
                </select>
                <div className={styles.dropdownLine} />
            </div>
            <div className={styles.sectionLine} />
        </div>
    )

}