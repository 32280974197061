import React from 'react';
import PropTypes from 'prop-types';
import styles from './SortListItem.module.css';
import { readGlobalState } from '../../hooks/StateHook';
import { useTranslation } from 'react-i18next';

export default function SortListItem({ text, clickAction }) {
  const [globalState] = readGlobalState();
  const cssClass = `${styles.container} ${globalState.selectedSort === text ? styles.selected : ''}`;
  const { t } = useTranslation();
  return (
    <div role="button" tabIndex={0} onKeyDown={clickAction} onClick={clickAction} className={cssClass}>
      <div className={globalState.selectedSort === text ? styles.selectedText : ''}>{t(text)}</div>
      {globalState.selectedSort === text ? (
        <div className={styles.iconContainer}>
          <img className={styles.checkIcon} alt="Check" src="https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/8x8/check_glyphs_white@1x.png" />
        </div>
      )
        : ''}
    </div>
  );
}

SortListItem.propTypes = {
  text: PropTypes.string.isRequired,
  clickAction: PropTypes.func.isRequired,
};
